<template>
  <div class="com-product-ProductService">
    <div class="title">{{ title }}</div>
    <a-row :gutter="10" style="width: 530px">
      <a-col :span="8" v-for="(item, index) in list" :key="index">
        <button
          class="btn-service user-select-none"
          :class="{ active: item.checked }"
          :disabled="item.disabled"
          @click="selectClick(item)"
        >
          {{ item.value }} {{ unit }}
          <cs-icon
            v-if="item.checked"
            name="icon-jiaobiao"
            type="symbol"
            style="float: right; margin-top: -6.5px; margin-right: -6px"
          />
        </button>
        <span v-if="item.disabled" class="disabled-icon">暂不支持</span>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    unit: String,
    list: Array,
  },
  data() {
    return {};
  },
  methods: {
    selectClick(item) {
      this.list.forEach((ele) => {
        if (ele.value === item.value) {
          item.checked = !item.checked;
        } else {
          ele.checked = false;
        }
      });
      this.$emit("select", {
        ...item,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.com-product-ProductService {
  display: flex;
  padding: 10px 0;
  .title {
    width: 100px;
    height: 34px;
    line-height: 34px;
  }
  .ant-col {
    height: 34px;
  }
  .btn-service {
    width: 158px;
    height: 34px;
    text-align: center;
    outline: none;
    border: 1px solid #e2e7f0;
    color: black;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: white;
    &:disabled {
      color: #aeaeae;
      background: #fbfbfb;
    }
    &.active {
      border: 1px solid @primary-color;
      color: @primary-color;
    }
  }
  .disabled-icon {
    font-size: 10px;
    position: relative;
    left: 110px;
    top: -50px;
    color: white;
    background-image: linear-gradient(-36deg, #969696 0%, #d8d8d8 100%);
    border-radius: 0 5px 0 5px;
    display: inline-block;
    padding: 0 3px;
    transform: scale(0.8);
  }
}
</style>